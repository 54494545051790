import { faker } from '@faker-js/faker';
import { components } from '_types/api';

export const createMockedMetadataObject = (
  overwrites?: Partial<doDOC.MetadataObject>,
): doDOC.MetadataObject => {
  const data_type =
    overwrites?.data_type ??
    faker.helpers.arrayElement<doDOC.MetadataObject['data_type']>([
      'date',
      'multi-select',
      'select',
      'string',
      'user',
    ]);

  const automatic = overwrites?.automatic ?? faker.datatype.boolean();

  const trigger =
    overwrites && 'trigger' in overwrites && overwrites.trigger
      ? overwrites.trigger
      : faker.random.word();

  const baseObject: components['schemas']['MetadataCommon'] = {
    id: overwrites?.id ?? faker.random.alphaNumeric(12),
    name: overwrites?.name ?? faker.random.words(),
    required: overwrites?.required ?? faker.datatype.boolean(),
    automatic,
    time: overwrites?.time ?? {
      creation: faker.date.recent().toISOString(),
      access: faker.date.recent().toISOString(),
      modification: faker.date.recent().toISOString(),
    },
    trigger: '',
  };

  if (baseObject.automatic) {
    baseObject.trigger = trigger;
  }

  switch (data_type) {
    case 'status':
    case 'user':
    case 'date': {
      return { ...baseObject, data_type };
    }
    case 'string': {
      const char_limit =
        overwrites && 'char_limit' in overwrites && overwrites.char_limit
          ? overwrites.char_limit
          : +faker.random.numeric(3);

      const search_by =
        overwrites && 'search_by' in overwrites
          ? overwrites.search_by
          : faker.helpers.uniqueArray(faker.name.firstName, 3);
      const searchable =
        overwrites && 'searchable' in overwrites ? overwrites.searchable : faker.datatype.boolean();

      return { ...baseObject, data_type, char_limit, search_by, searchable };
    }

    case 'multi-select':
    case 'select': {
      const options =
        overwrites && 'options' in overwrites && overwrites.options != null
          ? overwrites.options
          : faker.helpers.uniqueArray(() => {
              const fields = [faker.random.word(), faker.random.word()];
              const extra = [
                { value: fields[0] + '_0', label: fields[0] },
                { value: fields[1] + '_1', label: fields[1] },
              ];

              return { fields, extra };
            }, 3);

      return { ...baseObject, data_type, options };
    }
  }
};

export function createMockedMetadataList({
  defaultQuantity,
  metadata,
}: {
  defaultQuantity?: number;
  metadata?: Partial<doDOC.MetadataObject>[];
}): doDOC.MetadataObject[] {
  const defaultMetadata: NonNullable<typeof metadata> = Array(defaultQuantity ?? 0).fill(
    createMockedMetadataObject(),
  );

  //@ts-expect-error We are not guaranteeing that the data follows the rules of types.
  return [
    ...defaultMetadata,
    ...(metadata?.map((object) => {
      return createMockedMetadataObject(object);
    }) ?? []),
  ];
}
