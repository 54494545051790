export const DATA_TYPE_TRANSLATIONS = {
  user: 'User',
  string: 'String',
  select: 'Select',
  'multi-select': 'Multi-select',
  date: 'Date',
} as const;

export const AUTOMATION_TRIGGER_TRANSLATIONS = {
  obj_status: 'Content status',
  obj_author: 'Content author',
  veeva_version: 'Veeva version',
  veeva_published_date: 'Published date',
  veeva_expiration_date: 'Content expiration date',
  veeva_doc_number: 'Veeva document number',
  veeva_gmr3: 'GMR3 document ID',
} as const;
